import React from 'react'
import GitHubIcon from '@material-ui/icons/GitHub';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import '../styles/Footer.css'

const Footer = () => {
    const handleClickLinkedIn = () =>{
        window.open("https://www.linkedin.com/in/zongrui-liu/", '_blank');
    }
    const handleClickGithub = () =>{
        window.open("https://github.com/ZongruiL", '_blank')
    }
  return (
    <div className='footer'>
        <div className='socialMedia'>           
                <button onClick={handleClickLinkedIn} 
                        style={{ border: 'none', background: 'none', cursor: 'pointer'}}>
                    <LinkedInIcon/>
                </button>
                <button onClick={handleClickGithub} 
                    style={{ border: 'none', background: 'none', cursor: 'pointer'}}>
                    <GitHubIcon/>
                </button>
        </div>
        <p> &copy; 2023 zongruil.dev</p>
    </div>
  )
}

export default Footer