import React from 'react'
import { useParams } from 'react-router-dom'
import GitHubIcon from '@material-ui/icons/GitHub';
import { ProjectList } from '../helpers/ProjectList';
import '../styles/Project.css'

const Project = () => {

    const {id} = useParams();
    const project = ProjectList[id];
    const handleClick = () => {
        // Perform some action
        window.open(project.link, '_blank');
      };

  return (
    <div className='project'>
        <h1>{project.name}</h1>
        <img src={project.image} alt={project.name}/>
        <p>
            <b>Skills:</b> {project.skills}
        </p>
        <button onClick={handleClick} 
            style={{ border: 'none', background: 'none', cursor: 'pointer'}}>
            <GitHubIcon />
        </button>

    </div>
  )
}

export default Project