import React from 'react'
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component'
import "react-vertical-timeline-component/style.min.css"
import SchoolIcon from "@material-ui/icons/School"
import WorkIcon from "@material-ui/icons/Work"


const Experience = () => {
  return (
    <div className='experience'>
        <VerticalTimeline lineColor='#3e497a'>
            <VerticalTimelineElement 
            className='vertical-timeline-element--education'
            date='2017 - 2020'
            iconStyle={{background: "#3e497a", color:"#fff"}}
            icon={<SchoolIcon />}
            >
                <h3 className='vertical-timeline-element--title'>
                    University of Illinois at Urbana Champaign, USA
                </h3>
                <p>Bachelor of Science in Statistics</p>
            </VerticalTimelineElement>
            <VerticalTimelineElement 
            className='vertical-timeline-element--education'
            date='2021 - 2023'
            iconStyle={{background: "#3e497a", color:"#fff"}}
            icon={<SchoolIcon />}
            >
                <h3 className='vertical-timeline-element--title'>
                    Northeastern University, Canada
                </h3>
                <p>Master of Science in Computer Science</p>
            </VerticalTimelineElement>
            <VerticalTimelineElement 
            className='vertical-timeline-element--work'
            date='Sep 2022 - Dec 2022'
            iconStyle={{background: "#e9d35b", color:"#fff"}}
            icon={<WorkIcon />}
            >
                <h3 className='vertical-timeline-element--title'>
                    Full Stack Developer Intern - Omers
                </h3>
                <h4 className='vertical-timeline-element--subtitle'>
                    Toronto, Canada
                </h4>
            </VerticalTimelineElement>
            <VerticalTimelineElement 
            className='vertical-timeline-element--work'
            date='May 2023 - Aug 2023'
            iconStyle={{background: "#e9d35b", color:"#fff"}}
            icon={<WorkIcon />}
            >
                <h3 className='vertical-timeline-element--title'>
                    Web Developer Intern - Loblaw Digital
                </h3>
                <h4 className='vertical-timeline-element--subtitle'>
                    Toronto, Canada
                </h4>
            </VerticalTimelineElement>
        </VerticalTimeline>
    </div>
  )
}

export default Experience