import React from 'react'
import GitHubIcon from '@material-ui/icons/GitHub';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import '../styles/Home.css'
const Home = () => {
    const handleClickLinkedIn = () =>{
        window.open("https://www.linkedin.com/in/zongrui-liu/", '_blank');
    }
    const handleClickGithub = () =>{
        window.open("https://github.com/ZongruiL", '_blank')
    }
  return (
    <div className='home'>
        <div className='about'>
            <h2>Hi, My name is Zongrui</h2>
            <div className="prompt">
                <p>
                    A software developer with a passion for learning and creating
                </p>
                <button onClick={handleClickLinkedIn} 
                        style={{ border: 'none', background: 'none', cursor: 'pointer'}}>
                    <LinkedInIcon/>
                </button>
                <button onClick={handleClickGithub} 
                    style={{ border: 'none', background: 'none', cursor: 'pointer'}}>
                    <GitHubIcon/>
                </button>
            </div>
        </div>

        <div className='skills'>
            <h1>Skills</h1>
            <ol className='list'>
                <li className='item'>
                    <h2>Front-End</h2>
                    <span>React.js, HTML, CSS, SCSS, Redux</span>
                </li>
                <li className='item'>
                    <h2>Back-End</h2>
                    <span>
                        Node.js, Spring Boot, Flask, .NET, MySQL,
                        MongoDB, Docker, Kubernetes, AWS, GCP
                    </span>
                </li>
                <li className='item'>
                    <h2>Programming Languages</h2>
                    <span>Java, JavaScript, Python, C#, TypeScript</span>
                </li>
            </ol>
        </div>
    </div>
  )
}

export default Home