import Proj1 from '../assets/proj1.png'
import Proj2 from '../assets/proj2.png'
import Proj3 from '../assets/proj3.png'
import Proj4 from '../assets/proj4.png'
import Proj5 from '../assets/proj5.jpeg'
import Proj6 from '../assets/proj6.png'
import Proj7 from '../assets/proj7.png'

export const ProjectList = [
    {
      name: "Pizza Store",
      image: Proj1,
      skills: "React.js, Node.js, MySQL, Prisma, GCP",
      link: "https://github.com/ZongruiL/PizzaStore"
    },
    {
      name: "Ecommerce Website",
      image: Proj2,
      skills: "React,Node.js,MongoDB",
      link: "https://github.com/ZongruiL/Amazona"
    },
    {
      name: "Covid-19 Tracker",
      image: Proj3,
      skills: "Java, Android Studio",
      link: "https://github.com/ZongruiL/Covid-Tracker"
    },
    {
      name: "My Blog",
      image: Proj4,
      skills: "Python, Flask, HTML",
      link: "https://github.com/ZongruiL/MyBlog"
    },
    {
      name: "Video Converter",
      image: Proj5,
      skills: "Python, Flask, Docker, Kubernetes, RabbitMQ, MySQL, MondoDB",
      link: "https://github.com/ZongruiL/video_converter"
    },
    {
      name: "Snake Game",
      image: Proj6,
      skills: "JavaScript, CSS, HTML",
      link: "https://kevinsnakegame.onrender.com"
    },
    {
      name: "JobsDB Server",
      image: Proj7,
      skills: "java, Spring Boot, PostgreSQL, RabbitMQ, AWS, Docker",
      link: "https://github.com/ZongruiL/Job"
    }

  ];